import Header from '../components/Header'
import Meta from '../components/Meta'
import { Container } from 'react-bootstrap'
const Home = () => {
  // page content
  const pageTitle = 'Home'
  const pageDescription = 'welcome to VisitingPatra.com'

  return (
    <div>
      <Meta title={pageTitle}/>
      <Header head={pageTitle} description={pageDescription} />
      <Container>
        <p className="text-center vpHomeBlock">
        <a href="/anil">Anil Kumar Panigrahi</a>  
        <a href="/gopi">Gopi Krishna Sharma I</a>

        </p>
        </Container>
    </div>
  )
}

export default Home