const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <>
      <footer className='text-center text-capitalize footerLinks'>
        copyright VisitingPatra.com &copy; {year}, <a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-of-use">Terms Of Use</a>| contact <a href="mailto:support@VisitingPatra.com">support@VisitingPatra.com</a>
      </footer>
    </>
  )
}

export default Footer
