import React from 'react';
import Header from '../components/Header'
import Meta from '../components/Meta'
import { Container } from 'react-bootstrap'
import useFetch from "react-fetch-hook";

const Anil = () => {
  // page content


  const { isLoading, error, data } = useFetch("https://api.visitingpatra.workers.dev/anil");
  if (isLoading) return "Loading...";
  if (error) return "Error!";

  const pageTitle = data.firstName+' '+data.lastName
  const pageDescription = ''
  console.log(data);
  return (
    <div>

      <Meta title={pageTitle} />
      <Header head={pageTitle} description={pageDescription} />
      <Container>
        {/* {JSON.stringify(data, null, 2)} */}
        <p className="text-center">
        <span>{data.jobTitle}</span>
        </p>
        <p className="text-center">----------------</p>
        <p className="text-center">
        <strong>{data.companyName}</strong>
        </p>
        <p className="text-center">
        <span>{data.address1}</span>
        </p>
        <p className="text-center">
        <span>{data.address2}</span>
        </p>
        <p className="text-center">
        <span>t: {data.mobileNumber}</span>
        </p>
        <p className="text-center">
        <span>e: {data.email}</span>
        </p>
        <p className="text-center">
        <span>{data.website}</span>
        </p>


        <p className="text-center vpSocialBlock">
          <img src="/vpsmi/Facebook.svg" alt="Facebook" className="vpSocialIcon" />
          <img src="/vpsmi/Github.svg" alt="Facebook" className="vpSocialIcon" />
          <img src="/vpsmi/Instagram-New.svg" alt="Instagram" className="vpSocialIcon" />
          <img src="/vpsmi/LinkedIn.svg" alt="LinkedIn" className="vpSocialIcon" />
          <img src="/vpsmi/Pinterest.svg" alt="Pinterest" className="vpSocialIcon" />
          <img src="/vpsmi/Skype-.svg" alt="Skype" className="vpSocialIcon" />
          <img src="/vpsmi/Twitter.svg" alt="Twitter" className="vpSocialIcon" />
          <img src="/vpsmi/WhatsApp.svg" alt="WhatsApp" className="vpSocialIcon" />
          <img src="/vpsmi/Wordpress.svg" alt="Wordpress" className="vpSocialIcon" />
          <img src="/vpsmi/YouTube.svg" alt="YouTube" className="vpSocialIcon" />
        </p>
      </Container>
    </div>
  )
}

export default Anil