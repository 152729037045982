import Header from '../components/Header'
import Meta from '../components/Meta'
import { Container } from 'react-bootstrap'
const Gopi = () => {
  // page content
  const pageTitle = 'Gopi Krishna Sharma I'
  const pageDescription = ''

  return (
    <div>
      <Meta title={pageTitle} />
      <Header head={pageTitle} description={pageDescription} />
      <Container>
        <p className="text-center vpSocialBlock">
          <img src="/vpsmi/Facebook.svg" alt="Facebook" className="vpSocialIcon" />
          <img src="/vpsmi/Github.svg" alt="Facebook" className="vpSocialIcon" />
          <img src="/vpsmi/Instagram-New.svg" alt="Instagram" className="vpSocialIcon" />
          <img src="/vpsmi/LinkedIn.svg" alt="LinkedIn" className="vpSocialIcon" />
          <img src="/vpsmi/Pinterest.svg" alt="Pinterest" className="vpSocialIcon" />
          <img src="/vpsmi/Skype-.svg" alt="Skype" className="vpSocialIcon" />
          <img src="/vpsmi/Twitter.svg" alt="Twitter" className="vpSocialIcon" />
          <img src="/vpsmi/WhatsApp.svg" alt="WhatsApp" className="vpSocialIcon" />
          <img src="/vpsmi/Wordpress.svg" alt="Wordpress" className="vpSocialIcon" />
          <img src="/vpsmi/YouTube.svg" alt="YouTube" className="vpSocialIcon" />
        </p>
      </Container>
    </div>
  )
}

export default Gopi